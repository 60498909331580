


@font-face {
    font-family: 'Netto OT';
    src: url('../assets/fonts/netto/NettoOT.woff2') format('woff2'),
        url('../assets/fonts/netto/NettoOT.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}
@font-face {
    font-family: 'Netto OT';
    src: url('../assets/fonts/netto/NettoOT-Bold.woff2') format('woff2'),
        url('../assets/fonts/netto/NettoOT-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  
}

@font-face {
    font-family: 'Netto OT';
    src: url('../assets/fonts/netto/NettoOT-Light.woff2') format('woff2'),
        url('../assets/fonts/netto/NettoOT-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}
@font-face {
    font-family: "Netto";
    src: url("../assets/fonts/netto/netto.ttf");
    font-weight: 400;
    font-style: normal;
}


