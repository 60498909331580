@import "./fonts.scss";

body{
    font-family: 'Netto OT' ,sans-serif;
    font-weight: 400;
}
$netto: "Netto";
// Used Color Variables

$themecolor: #642D90;
$themefontColor: #ffffff;
@mixin gradientcolor{
    background: #812b8e; 
    background: -moz-linear-gradient(top,  #812b8e 0%, #522e90 100%); 
    background: -webkit-linear-gradient(top,  #812b8e 0%,#522e90 100%);
    background: linear-gradient(to bottom,  #812b8e 0%,#522e90 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#812b8e', endColorstr='#522e90',GradientType=0 ); 
   }
   @mixin shadow1 {    box-shadow: 0px 3px 6px 0 #0000001a;}



.btn{
    // text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 700!important;
    min-width: 100px;
    height: 35px;
    line-height: 1;
}
.btn-outline-secondary {
    color: #1a2126;
    border-color: #d9d9d9;
}
.btn-secondary {
    color: #fff;
    background-color: #6c757d69;
    border-color: #6c757d4a;
}
.btn-outline-primary{
    color: #1a2126;
    &:hover{
        color: #fff;
    }
}
.ql-editor{
    padding: 0!important;
}

.form-control {
    font-size: 0.8rem;
}

